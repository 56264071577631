import "./SuccessPage.css"; // Import your CSS file for styling
function SuccessPageNotPlan() {
  return (
    <div className="box">
      <div className="success alert">
        <div className="alert-body warn-message">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <circle cx="12" cy="12" r="10" />
            <line x1="12" y1="8" x2="12" y2="12" />
            <line x1="12" y1="16" x2="12" y2="16" />
          </svg>
          <h2 className="success-heading">Payment Warning!</h2>
          <p className="success-text">
            Thank you for choosing Coconut Market App.
          </p>
          <p className="instruction-text">
            <b>Press the back button to return to the application.</b>
          </p>
        </div>
      </div>
    </div>
  );
}
export default SuccessPageNotPlan;
