import "./App.css";
import Cancel from "./components/Cancel";
import PayamentCard from "./components/PayamentCard";
import SuccessPage from "./components/SuccessPage";
import SuccessPageNotPlan from "./components/SuccessPageNotPlan";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
function App() {
  return (
    <section className="card-list">
      <BrowserRouter>
        <Routes>
          <Route path="/query" element={<PayamentCard />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/failure" element={<SuccessPageNotPlan />} />
          <Route path="/cancel" element={<Cancel />} />
        </Routes>
      </BrowserRouter>
    </section>
  );
}

export default App;
