import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import {  useNavigate } from "react-router-dom";
const apiUrl = process.env.REACT_APP_API_URLS;
const razorPayScriptUrl = "https://checkout.razorpay.com/v1/checkout.js";
const logo = process.env.REACT_APP_API_IMAGE + "/logo/cmalogoo.webp";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}
async function getRazorPayOrder(userDetail, paramsData) {
  // Create a new order
  const orderResponse = await axios.get(
    `${apiUrl}/razarpay/${paramsData?.postplanId}/${userDetail?.amount}/${paramsData?.postuserId}/${userDetail?.token}`
  );
  return orderResponse.data;
}

function PayamentCard() {
  const navigate = useNavigate ();
  const [showModal, setShowModal] = useState(true);
  const [userInfo, setUserInfo] = useState({});
  
  const searchParams = new URLSearchParams(window.location.search);
  console.log("searchParams",searchParams)
  console.log("searchParams",searchParams.get("userId"))
  const postuserId = searchParams.get("userId");
  const postplanId = searchParams.get("planId");
  const [currentDate] = useState(new Date());
  const addDays = (date, days) => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + days);
    return newDate;
  };
  const getUserPlanInfo = async () => {
    var detail = await axios.get(
      `${apiUrl}/getUserDetailForPayment/${postplanId}/${postuserId}`
    );
    setUserInfo(detail?.data?.modifiedData);
  };
  const displayRazorpay = async () => {
    try {
      // Extract parameters from the URL

      const paramsData = {
        postuserId,
        postplanId,
      };
      // Fetch user details
      const userResponse = await axios.get(
        `${apiUrl}/getUserDetail/${postplanId}/${postuserId}`
      );
      console.log("userResponse==============", userResponse);
      const userDetail = userResponse?.data?.modifiedData || {};
      const { amount, token } = userDetail;

      // Load the Razorpay script
      const scriptLoaded = await loadScript(razorPayScriptUrl);
      if (!scriptLoaded) {
        throw new Error(
          "Razorpay SDK failed to load. Please check your internet connection."
        );
      }

      const razorpayOrder = await getRazorPayOrder(userDetail, paramsData);

      // Fetch user profile
      const userProfileResponse = await axios.get(
        `${apiUrl}/viewUserProfile/${razorpayOrder?.userId}`
      );
      const userInfo = userProfileResponse.data.data[0] || {};

      // Configure Razorpay options
      // debugger;
      // Initialize and open the payment dialog
      const paymentObject = new window.Razorpay(
        loadRazorOptions(amount, razorpayOrder, userInfo)
      );
      paymentObject.open();

      // Handle payment success
      paymentObject.on("payment.success", async function (response) {
        console.log("payment.succ", response);
        console.log("payment.success");
      });

      // Handle payment error
      paymentObject.on("payment.error", async function (response) {
        // Handle error and possibly send a push notification
        console.log("payment.error", response);
      });
    } catch (error) {
      // Handle any errors here
      console.error("Payment error:", error);
      // Display an error message to the user
      alert("Payment error. Please try again later.");
    }
  };
  useEffect(() => {
    getUserPlanInfo();
  }, []);

  const handleModalSubmit = async () => {
    try {
      setShowModal(false);
      displayRazorpay();
    } catch (error) {
      console.error("Modal submission error:", error);
    }
  };

  
  //const handleClose = () => setShowModal(false);displayRazorpay();
  const handleClose = async () => {
    try {
      setShowModal(false);
      navigate("/cancel");
    } catch (error) {
      console.error("Modal submission error:", error);
    }
  };
  return (
    <div>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-center">
            Welcome to Coconut Market App
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <h3>Payment Confirmation</h3>
          <h6 style={{ color: "#ff846f" }}>User Details</h6>
          <p> Name : {userInfo?.userInfo?.name}</p>
          <p> Mobile Number : {userInfo?.userInfo?.mobile}</p>
          <p>
            {" "}
            {userInfo?.userInfo?.email
              ? `Email : ${userInfo?.userInfo?.email}`
              : ""}
          </p>
          

          <h6 style={{ color: "#ff846f" }}>Upgrade Plan Details</h6>
          <span>
            <p>
              {" "}
              Plan : <b>{userInfo?.upgardePlan?.name}</b>
            </p>
            <p>
              {" "}
              Plan Price :{" "}
              <b>
                {" "}
                {userInfo?.upgardePlan?.amount} / {userInfo?.upgardePlan?.days}{" "}
                Day
              </b>
            </p>

            <p>
              {" "}
              Plan Expiry :{" "}
              <b>
                {addDays(
                  currentDate,
                  userInfo?.upgardePlan?.days
                ).toLocaleDateString("en-GB", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })}
              </b>
            </p>
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Not Now
          </Button>
          <Button variant="primary" onClick={handleModalSubmit}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

function loadRazorOptions(amount, razorpayOrder, userInfo) {
  //debugger;
  const options = {
    key: razorpayOrder?.razorpayKey,
    amount: amount.toString(),
    currency: razorpayOrder?.currency,
    name: "Coconut Market App",
    description: "Plan Subscription",
    image: logo,
    order_id: razorpayOrder?.id,
    handler: async function (response) {
      console.log("response", response);

      const data = {
        orderCreationId: razorpayOrder?.id,
        razorpayPaymentId: response.razorpay_payment_id,
        razorpayOrderId: response.razorpay_order_id,
        razorpaySignature: response.razorpay_signature,
        amount: amount,
        userid: razorpayOrder?.userId,
        planid: razorpayOrder?.planid,
      };

      const result = await axios.post(apiUrl + "/payment/success", data);
      
      // Handle the result and perform redirection
      if (result.data.msg === "success") {
        console.log("***********************")
        console.log("Success")
        console.log("***********************")
        const dataSuc = {
          userId: razorpayOrder?.userId,
          planId: razorpayOrder?.planid,
        };
        // Redirect to success page

        const updatePlan = await axios.post(
          apiUrl + "/application/subscribePlans",
          dataSuc,
          {
            headers: {
              Authorization: `Bearer ${userInfo.Token}`,
            },
          }
        );
        if (updatePlan?.data?.status) {
          const currentDomain = window.location.origin; // Get the current domain
          window.location.href = currentDomain + "/success";
        } else {
          const currentDomain = window.location.origin; // Get the current domain
          window.location.href = currentDomain + "/failure";
        }
        
      } else {
        // Redirect to error page
        const currentDomain = window.location.origin; // Get the current domain
        window.location.href = currentDomain + "/failure";
      }
      //alert(result.data.msg);
    },
    notes: {
      address: "Coconut Market App",
    },
    theme: {
      color: "#ff846f",
    },
    // prefill with user data
    prefill: {
      name: userInfo.name,
      email: userInfo.email,
      contact: userInfo.mobile,
    },
    // ... other options ...
  };
  return options;
}

export default PayamentCard;
